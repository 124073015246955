import { createRouter, createWebHistory } from 'vue-router'
import taohaomao from '../views/HomeView.vue'
import thhHome from '../views/thhHome.vue'
import AboutView from '../views/AboutView.vue'
import thwHome from '../views/thwHome.vue'
import mhbHome from '../views/mhbHome.vue'
import taohaohao from '../views/taohaohao.vue'
import thbHome from '../views/thbHome.vue'
import taohaowangHome from "../views/taohaowangHome.vue"
import maihaowangHome from "../views/maihaowangHome.vue"
import newThwHome from "../views/newThwHome.vue"
import iosMhb from "../views/iosMhb.vue"
const routes = [
  {
    path: '/',
    name: 'home',
    component: AboutView
  },
  {
    path: '/thwHome',
    name: 'thwHome',
    component: thwHome
  },
  {
    path: '/mhbHome',
    name: 'mhbHome',
    component: mhbHome
  },
  {
    path: '/taohaohao',
    name: 'taohaohao',
    component: taohaohao
  },

  {
    path: '/thbHome',
    name: 'thbHome',
    component: thbHome
  },
  {
    path: '/taohaowangHome',
    name: 'taohaowangHome',
    component: taohaowangHome
  },
  {
    path: '/maihaowangHome',
    name: 'maihaowangHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: maihaowangHome
  },
  {
    path: '/taohaomao',
    name: 'taohaomao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: taohaomao
  },
  {
    path: '/index',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: thhHome
  },
  {
    path:'/newThwHome',
    name:'newThwHome',
    component: newThwHome
  },
  {
    path:'/iosMhb',
    name:'iosMhb',
    component: iosMhb
  }

  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
